import { defineAsyncComponent } from 'vue'

export default {
    install(app) {
        const components = import.meta.glob('@/Components/Base/**/*.vue', { eager: true, })
        Object.entries(components).forEach(([path, definition]) => {
            const name = path
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
            app.component(name, definition.default)
        })

        const directives = import.meta.glob('@/Directives/Base/**/*.js', { eager: true, })
        Object.entries(directives).forEach(([path, definition]) => {
            const name = path
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
            app.directive(name, definition.default)
        })
    },
}

