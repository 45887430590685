export default {
    created(el, binding, vnode, prevVnode) {},
    beforeMount(el, binding, vnode) {
//        const target = document.querySelector('[collapse-toggle-target=' + binding.value + ']')
        el.addEventListener('blur', (val) => {
//            if (target.classList.contains('hidden')) {
//                target.classList.remove('hidden')
//            } else {
//                target.classList.add('hidden')
//            }
            console.log(el)
        })
//        target.addEventListener('blur', (val) => {
//console.log('blue')
//        })
    },
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {}
}

