export default {
    created(el, binding, vnode, prevVnode) {},
    beforeMount(el, binding, vnode) {
    },
    mounted(el, binding, vnode) {
        const target = document.querySelector('[collapse-toggle-target=' + binding.value + ']')
        el.addEventListener('click', (val) => {
//console.log('click')
            if (target.classList.contains('hidden')) {
                target.classList.remove('hidden')
//console.log('remove hidden')
//target.classList.add('absolute')
                // set tabIndex to get focus
                if (target.tabIndex === -1) {
                    target.tabIndex = 0
                }
                target.focus()
            } else {
                target.classList.add('hidden')
//console.log('add hidden')
            }
        })
        target.addEventListener('focusout', (val) => {
            setTimeout(function(){
//console.log('focusout')
                if (!target.contains(document.activeElement)) {
                    target.classList.add('hidden')
//console.log('add hidden')
                }
            }, 200)
        })
    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {}
}

